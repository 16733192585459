<script setup>
 const props = defineProps({
  button: {
   type: [Object, Boolean],
   required: false,
   default: false,
  },
  label: {
   type: String,
   required: false,
   default: '',
  },
  link: {
   type: [String, Boolean],
   required: false,
   default: false,
  },
  icon: {
   type: [String, Boolean],
   required: false,
   default: false,
  },
  title: {
   type: String,
   required: false,
   default: '',
  },
  version: {
   type: String,
   required: false,
   default: 'red',
   //  validator: (value) => ['red', 'red-fill', 'white', 'black', 'dark-gray', 'gray-700'].includes(value),
  },
  disabled: {
   type: Boolean,
   required: false,
   default: false,
  },
  forceInternalLink: {
   type: Boolean,
   required: false,
   default: false,
  },
  forceExternalLink: {
   type: Boolean,
   required: false,
   default: false,
  },
 });

 const { button, label, link, icon, title, disabled, version } = toRefs(props);
</script>

<template>
 <MessButton
  class="fs-12 project-button"
  :data-version="version"
  :button="button"
  :label="label"
  :link="link"
  :icon="icon"
  :title="title"
  :disabled="disabled"
  :forceInternalLink="forceInternalLink"
  :forceExternalLink="forceExternalLink"
 >
  <slot />
 </MessButton>
</template>

<style lang="scss">
 .project-button {
  display: inline-block;
  padding: 1em 2em;

  border: 1px solid;
  border-radius: 3.333em;

  transition: var(--transition);

  &.disabled {
   cursor: not-allowed;
  }

  span {
   font-size: inherit;
   font-weight: 900;
   text-transform: uppercase;
   line-height: 1.231; /* 14.772px */
   letter-spacing: 1.38px;
   color: inherit;
  }

  &.transparent,
  &[data-version='transparent'] {
   color: var(--white);
   &:hover {
    background-color: var(--white);
    color: var(--red);
   }
  }

  &.red,
  &[data-version='red'] {
   color: var(--red);
   &:hover {
    background-color: var(--red);
    color: var(--white);
   }
  }

  &.red-fill,
  &[data-version='red-fill'] {
   color: var(--white);
   background-color: var(--red);
   &:hover {
    color: var(--white);
    background-color: var(--black);
   }
  }

  &.white,
  &[data-version='white'] {
   background-color: var(--white);
   border-color: var(--white);
   color: var(--black);
   &:hover {
    background-color: var(--red);
    border-color: var(--red);
    color: var(--white);
   }
  }

  &.black,
  &[data-version='black'] {
   color: var(--black);
   &:hover {
    background-color: var(--white);
    color: var(--red);
   }
  }

  &.gray-700,
  &[data-version='dark-gray'] {
   color: var(--gray-700);
  }

  &.dark-gray,
  &[data-version='dark-gray'] {
   color: var(--white);
   background-color: var(--gray-900);
   &:hover {
    background-color: var(--white);
    color: var(--gray-900);
   }
  }

  &:hover {
   .icon-wrapper {
    // margin-left: 0.5em;
   }
  }
 }
</style>
